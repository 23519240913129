import React, { useEffect, useState } from "react";
import { ApisService } from "../../../../../app/core/apis.service";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { Search } from "../../../../../components/search/search";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { DateRangeSelector } from "../../../../../components/date-filter/daterangeselector";
import moment from "moment";
import { useParams } from "react-router-dom";

export const OrdersList = () => {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>({
    DateFrom: moment().format("YYYY-MM-DD"),
    DateTo: moment().format("YYYY-MM-DD"),
  });
  const [transactionsData, setTransactionsData] = useState<any>([]);

  useEffect(() => {
    loadTransactions(selectDate.DateFrom, selectDate.DateTo);
  }, [selectDate]);

  async function loadTransactions(
    dateFrom: string,
    dateTo: string
  ): Promise<void> {
    setLoading(true);
    try {
      const payload = {
        buId: params.org,
        filters: {
          DateFrom: dateFrom,
          DateTo: dateTo,
          status: null,
          name: null,
          transaction_type: null,
          offline: null,
        },
      };
      const encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.transactionApi(
        "findByTransactionBuId",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      const response = transactionsData?.results;

      if (response?.length > 0) {
        response.forEach((element: any) => {
          element.date = moment(element?.created_date).format("DD-MM-YYYY");
          element.name = element?.buyer?.name;
          element.buyer_app = element?.ondc?.buyer_app;
        });
        const orderData = response.filter(
          (order: any) => order.type === "ONDC"
        );
        setTransactionsData(orderData);
      } else {
        setTransactionsData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onPageChange = (data: any) => {
    setPageCount((prev: any) => ({ ...prev, limit: data }));
  };

  const onSelectPage = (data: any) => {
    setPageCount((prev: any) => ({ ...prev, page: data }));
  };

  const getDateRange = (data: { startDate: string; endDate: string }) => {
    setSelectData({
      DateFrom: moment(data.startDate).format("YYYY-MM-DD"),
      DateTo: moment(data.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleSearch = () => {
    loadTransactions(selectDate.DateFrom, selectDate.DateTo);
  };

  const handleClear = () => {
    setClearDates(true);
    setSelectData({
      DateFrom: moment().format("YYYY-MM-DD"),
      DateTo: moment().format("YYYY-MM-DD"),
    });
    setClearDates(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
          &nbsp;
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading ? (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <DataTable
            tableData={transactionsData}
            TableCols={TransactionsDataGridCols}
            onActions={(e: any) => {}}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e: any) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
