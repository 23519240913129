import React, { useEffect, useState } from "react";
import { ApisService } from "../../../../../app/core/apis.service";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { Search } from "../../../../../components/search/search";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import { DateRangeSelector } from "../../../../../components/date-filter/daterangeselector";
import moment from "moment";
import { useParams } from "react-router-dom";

export const InvoicesList = () => {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [transactionsData, setTransactionsData] = useState<any>([]);

  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>({
    DateFrom: moment().format("YYYY-MM-DD"),
    DateTo: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    loadTransactions(selectDate.DateFrom, selectDate.DateTo);
  }, [selectDate]);

  async function loadTransactions(
    dateFrom: string,
    dateTo: string
  ): Promise<void> {
    setLoading(true);
    try {
      let payload = {
        buId: params.org,
        filters: {
          DateFrom: dateFrom,
          DateTo: dateTo,
          status: null,
          name: null,
          transaction_type: null,
          offline: null,
        },
      };
      let encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.transactionApi(
        "findByTransactionBuId",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      console.log(transactionsData, "transactionsData");
      const response = transactionsData?.results;
      console.log(response, "response");
      if (Array.isArray(response)) {
        response.forEach((element: any) => {
          element.invoice_date = element?.invoice_details
            ? moment(element?.invoice_details[0]?.invoice_date).format(
                "DD-MM-YYYY"
              )
            : "";
          element.buyer_app = element?.ondc?.buyer_app;
          element.sold_to = element?.sold_to;
          element.invoice = element?.invoice_details
            ? element?.invoice_details[0]?.bill_no
            : "";
          element.invoice_amount = element?.invoice_details
            ? element?.invoice_details[0]?.invoice_amount
            : "";
          element.packing_status =
            element?.invoice_details &&
            element?.invoice_details[0]?.package_details
              ? "completed"
              : "pending";
          element.delivery_status =
            element?.invoice_details &&
            element?.invoice_details[0]?.delivery_details
              ? "completed"
              : "pending";
        });
      } else {
        console.error("Error: 'response' is either undefined or not an array.");
      }

      const orderData = response.filter((order: any) => order?.type === "ONDC");
      console.log(orderData);
      setTransactionsData(orderData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const getDateRange = (data: { startDate: string; endDate: string }) => {
    setSelectData({
      DateFrom: moment(data.startDate).format("YYYY-MM-DD"),
      DateTo: moment(data.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleClear = () => {
    setClearDates(true);
    setSelectData({
      DateFrom: moment().format("YYYY-MM-DD"),
      DateTo: moment().format("YYYY-MM-DD"),
    });
    setClearDates(false);
  };

  const handleSearch = () => {
    loadTransactions(selectDate.DateFrom, selectDate.DateTo);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
          &nbsp;
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={transactionsData}
            TableCols={TransactionsDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e: any) => onSelectPage(e)}
            pageCount={pageCount}
          />
        )}
      </div>
    </div>
  );
};
