import React, { useEffect, useState } from "react";
import { DataTable } from "../../../../../components/data-table";
import { TransactionsDataGridCols } from "./data-grid-cols";
import {
  dataDecrypt,
  encrypt,
} from "../../../../../app/utility/encryption-helper";
import { ApisService } from "../../../../../app/core/apis.service";
import { Search } from "../../../../../components/search/search";
import { useParams } from "react-router-dom";
import { TiHomeOutline } from "react-icons/ti";
import { CiLocationOn } from "react-icons/ci";
import { PiPhoneCallLight } from "react-icons/pi";
import { MdOutlineMail } from "react-icons/md";


export const OfflineConnections = () => {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [transactionsData, setTransactionsData] = useState<any>([]);
  const [clearDates, setClearDates] = useState(false);
  const [selectDate, setSelectData] = useState<any>();

  useEffect(() => {
    loadOfflineConnections();
  }, []);

  async function loadOfflineConnections(): Promise<void> {
    setLoading(true);
    try {
      let payload = { buId: params.org, filters: { name: null } };
      let encryptedData = encrypt(JSON.stringify(payload));
      const res = await ApisService.createInvitation(
        "findAllContactByUsers",
        encryptedData
      );
      const transactionsData = JSON.parse(dataDecrypt(res.data.data));
      console.log(transactionsData);
    
      setTransactionsData(transactionsData?.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };

  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        {/* <div className="col-md-4 my-3">
          <DateRangeSelector 
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div> */}
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
        <div className="col-md-2"></div>
        {/* <div className="col-md-3 my-3">
          <Form.Select aria-label="Default select example">
            <option value="1">External</option>
            <option value="2">Internal</option>
          </Form.Select>
        </div> */}
      </div>
      <div className="col-12 px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}

        {!loading &&
          transactionsData?.length > 0 &&
          transactionsData.map((value: any, index: any) => (
            <div className="card mb-2" key={index}>
              <div className="card-body py-2 border-0 box-shadow">
                <div className="d-flex align-items-center "><TiHomeOutline size={20} /><span className="ms-2 fs-14 fw-600 mt-1">{value?.name}</span></div>
                <div className="d-flex align-items-center py-1"><PiPhoneCallLight size={20} /><span className="ms-2 fs-14 fw-500 mt-1">{value?.contact}</span></div>
                <div className="d-flex align-items-center "><MdOutlineMail size={20} /><span className="ms-2 fs-14 fw-500 mt-1">{value?.email_id}</span></div>
            </div>
            </div>
          ))}
      </div>
    </div>
  );
};
