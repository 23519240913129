import React, { useEffect, useState } from "react";
import { RegistrationDataGridCols } from "./data-grid-cols";
import { ApisService } from "../../../app/core/apis.service";
import { dataDecrypt } from "../../../app/utility/encryption-helper";
import { DataTable } from "../../../components/data-table";
import { Search } from "../../../components/search/search";
import { DateRangeSelector } from "../../../components/date-filter/daterangeselector";
import moment from "moment";

export const RegistrationDetails = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectDate, setSelectData] = useState<any>();
  const [clearDates, setClearDates] = useState(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const [registrationData, setRegistrationData] = useState<any>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  async function loadUsers(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.usersApi("getAll", "");
      const registrationData = JSON.parse(dataDecrypt(res.data.data));
      console.log(registrationData, "users adaa");
      const response = registrationData;
      console.log(response, "response");
      response.forEach((el: any) => {
        el.date = moment(el.created_date).format("DD-MM-YYYY");
        el.no_of_bu =
          el?.business_units?.length > 0 ? el.business_units.length : 0;
      });
      setRegistrationData(registrationData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in transaction:", error);
    }
  }

  const onActions = (data: any) => {};

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
  };
  const getDateRange = (data: any) => {
    setSelectData(data);
  };

  const handleToggleChange = (updatedRow: any, index: number) => {
    console.log("Toggle changed for row:", updatedRow);
    const updatedData = [...registrationData];
    updatedData[index] = { ...updatedData[index], status: updatedRow.status };

    // Update the local state
    setRegistrationData(updatedData);
  };

  return (
    <div className="container-fluid content-bg-color">
      <h4 className="primary-color fw-bold mt-3">Registrations</h4>
      <div className="row">
        <div className="col-md-4 px-0 my-3">
          <Search customClass={true} />
        </div>
        <div className="col-md-4 my-3">
          <DateRangeSelector
            handleDataRange={getDateRange}
            datefilterApply={false}
            clearDates={clearDates}
          />
        </div>
        <div className="col-md-3 my-3">
          <button className="btn btn-primary">Search</button>&nbsp;
          <button className="btn btn-secondary">Clear</button>
        </div>
      </div>
      <div className="px-0 my-2">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            tableData={registrationData}
            TableCols={RegistrationDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
            onToggleChange={handleToggleChange}
          />
        )}
      </div>
    </div>
  );
};
