import { useEffect, useState } from "react";
import { LookupService } from "../../../app/core/lookup.service";
import { useParams } from "react-router-dom";
import { FormatIndianDate } from "../../../app/utility/formatIndianDate";
import { Breadcrumb } from "../../../components/breadcum";
import { OndcService } from "../../../app/core/ondc.service";

export const ONDCIgmView = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [ticketsData, setTicketsData] = useState<any>();
  const [replayData, setReplayData] = useState<any>();
  const [replayTicket, setReplayTicket] = useState<boolean>(false);

  useEffect(() => {
    setTicketsData("");
    loadTickets();
  }, []);
  const loadTickets = async () => {
    setLoading(true);
    try {
      const messageData = await await OndcService.getOndcIgmData();
      setLoading(false);
      const response = messageData.filter((el: any) => el._id === params.id);
      setTicketsData(response[0]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const writeReplay = () => {
    setReplayTicket(true);
  };

  const onSubmitData = async () => {
    console.log(replayData, "replayData");
  };

  const handleInput = (e: any) => {
    setReplayData(e.target.value);
  };

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Tickets",
      value: "Tickets",
      routerLink: `/dashboard/ondc-igm`,
      isActive: false,
    },
    {
      label: "Info",
      value: "Info",
      routerLink: ``,
      isActive: true,
    },
  ]);

  return (
    <div className="container-fluid content-bg-color">
      <Breadcrumb breadCrumbList={breadcrumbInfo} />
      {!loading ? (
        <div>
          <h5 className="fw-600">Ticket ID : {params.id}</h5>
          <div className="card mb-2">
            <div className="card-body">
              <h6 className="fw-bold fs-5 mb-2">Ticket details</h6>

              <h6 className="fw-bold mb-0 mt-2">Ticket ID</h6>
              <div className="fs-14">{ticketsData?._id}</div>

              <h6 className="fw-bold mb-0 mt-2">Created</h6>
              <div className="fs-14">
                {FormatIndianDate(ticketsData?.created_dt, "")}
              </div>

              <h6 className="fw-bold mb-0 mt-2">Issue Type</h6>
              <div className="fs-14">
                {ticketsData?.message?.issue?.issue_type}
              </div>
            </div>
          </div>
          <div>
            {!replayTicket ? (
              <div className="text-end">
                <button
                  className="primary-bg rounded px-4 py-1 text-white border-0 my-3"
                  onClick={writeReplay}
                >
                  Reply
                </button>
              </div>
            ) : (
              <div className="my-3">
                <label className="fw-600">Write your reply here</label>
                <textarea
                  className="form-control"
                  id="exampleTextarea"
                  placeholder="Enter your message here"
                  onChange={(e) => handleInput(e)}
                ></textarea>
                <div className="text-end">
                  <button
                    className="primary-bg rounded px-4 py-1 text-white border-0 mb-3 mt-1"
                    onClick={onSubmitData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="card border-30 box-shadow rounded">
            <div className="card-body">
            <div className="row">
                    <div className="col-4 right-border ">
                    <div>{ticketsData?.message?.issue?.complainant_info?.person?.name}</div>
                      <div>{ticketsData?.message?.issue?.complainant_info?.contact?.phone}</div>
                      <div>{ticketsData?.message?.issue?.complainant_info?.contact?.email}</div>
                      <div className="fs-14">
                        {FormatIndianDate(ticketsData?.message?.issue?.created_at, "datetime")}
                      </div>
                    </div>
                    <div className="col-8">{ticketsData?.message?.issue?.description?.long_desc}</div>
                  </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
